define('ohs/serializers/application', ['exports', 'ember-data', 'ember-inflector', 'lodash'], function (exports, _emberData, _emberInflector, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    keyForRelationship: function keyForRelationship(key) {
      return key;
    },


    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      var object = {};
      var propertyName;
      var serializedObject = this.serialize(record, options);
      serializedObject = serializedObject["data"];
      var attributes = serializedObject["attributes"];

      if (record["id"] != undefined) {
        object["id"] = record["id"];
      }

      for (propertyName in attributes) {
        var obj = {};
        object[propertyName.camelize()] = attributes[propertyName];
      }

      var relationships = serializedObject["relationships"];
      for (propertyName in relationships) {
        var dataObj = relationships[propertyName];
        dataObj = dataObj["data"];
        if (dataObj) {
          if (dataObj.length) {
            var relObj = [];
            for (var i = 0; i < dataObj.length; i++) {
              relObj.push(dataObj[i]);
            }
            object[propertyName.camelize()] = relObj;
          } else {
            object[propertyName.camelize()] = dataObj;
          }
        }
      }

      return Ember.merge(data, object);
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      var modelName = (0, _emberInflector.pluralize)(primaryModelClass.modelName);
      //There are a few instances where they include meta with the payload. When they do that then send an object that looks like below
      //In order to handle this rare case, we try to assign the incomingPayload to payload.data, if it doesn't exist then we just assign
      //the payload to the incoming payload.
      /*
        Example payload that contains meta data and a "data" property
        {
          data: <models> or <model>,
          meta: <meta information>
        }
      */
      var incomingPayload = payload.data || payload;
      var includes = [];
      var jsonApiPayload = {};

      //if payload is an array then we need to map each object in it 
      if (_lodash.default.isArray(incomingPayload)) {
        jsonApiPayload.data = incomingPayload.reduce(function (data, record) {
          return data.concat(_this.mapPayload(record, modelName, includes));
        }, []);
      }
      //if payload is just a single object, we only need to map that one object
      else if (incomingPayload) {
          jsonApiPayload.data = this.mapPayload(incomingPayload, modelName, includes);
        }

      jsonApiPayload.included = includes, jsonApiPayload.meta = payload.meta || {};

      //calling super with new payload
      return this._super(store, primaryModelClass, jsonApiPayload, id, requestType);
    },

    /**
     * Maps a single object to be JSONAPI compliant object. 
     * 
     * Example: 
     * "type": "articles",
     *     "id": "1",
     *     "attributes": {
     *       "title": "JSON API paints my bikeshed!",
     *       "body": "The shortest article. Ever.",
     *       "created": "2015-05-22T14:56:29.000Z",
     *       "updated": "2015-05-22T14:56:28.000Z"
     *     },
     *     "relationships": {
     *       "author": {
     *         "data": {"id": "42", "type": "people"}
     *       }
     *     }
     * @param {*} record
     * @param {*} type
     * @param {*} includes
     */
    mapPayload: function mapPayload(record, type, includes) {
      var _this2 = this;

      (false && !(record.hasOwnProperty('id')) && Ember.assert('Object to seralize must contain an "id" property', record.hasOwnProperty('id')));
      (false && !(!_lodash.default.isNull(type)) && Ember.assert('Object must have a non-null type', !_lodash.default.isNull(type)));

      //initialize properties

      var object = {
        type: type,
        id: record.id,
        attributes: {},
        relationships: {}
      };
      var attributes = object.attributes;
      var relationships = object.relationships;

      //loop through each propertyName in the object
      //filters out the 'id' and the meta record as we do not want to map those as attributes
      Object.keys(record).filter(function (key) {
        return key != 'id' && key != 'meta';
      }).forEach(function (key) {
        var propertyValue = record[key];

        // Matt -> "THIS IS HERE TO HANDLE THE REFLEXIVE RELATIONSHIPS IN THE CLASSLIBRARYFOLDER MODEL"
        // I'm guessing he is just trying to map the two properties in the payload to the same model. This is because our API can't specify document types.
        var type = key == "parentFolder" || key == "childFolders" ? 'classLibraryFolder' : key;

        //TODO: Uncomment this only AFTER the api supports meta on every entity ( or at least the classLibraryFolder entity )
        //let type = key;

        //NOTE: Adding null records to the new payload can cause odd things. Most notable is the avatar dissapearing, because a null user is returned from another payload. 
        // This is why we only consider arrays, objects, and not null properties when adding objects to the JSONAPI payload
        // This is really a limitation of our API. We shouldn't be returning inconsistant data, maybe in time this can be fixed.

        //Arrays are assumed to be relationships 
        if (_lodash.default.isArray(propertyValue)) {
          //Number arrays are assumed to be relationships without included values 
          if (propertyValue.every(function (v) {
            return _lodash.default.isNumber(v);
          })) {
            //create relationships for all the ids in the array
            _this2.createRelationships(object, propertyValue, type);
          }
          //Object arrays are assumed to be relationships with included values
          else if (propertyValue.every(function (v) {
              return _lodash.default.isObject(v) && v.hasOwnProperty('id');
            })) {
              //map each of the nestedObjects 
              _this2.mapNestedObjects(object, propertyValue, type, includes);
            }
            //Anything else should be treated as an attribute
            else {
                attributes[key.dasherize()] = propertyValue;
              }
        }
        //Objects are assumed to be relationships that are also included 
        else if (_lodash.default.isObject(propertyValue)) {
            //map the nested object
            _this2.mapNestedObject(object, propertyValue, type, includes);
          }
          //Not null properties are assumed to be attributes
          else if (!_lodash.default.isNull(propertyValue)) {
              attributes[key.dasherize()] = propertyValue;
            }
      });

      return object;
    },

    mapNestedObjects: function mapNestedObjects(parent, objects, type, includes) {
      var _this3 = this;

      objects.forEach(function (object) {
        //Example of what the meta object would look like inside of an object 
        /*
          {
            meta: {
              included: false,
              type: 'question'
            },
            id: 7
          }
        */
        if (object.meta && object.meta.included) {
          //object has meta and is said to be included
          //set the type of the meta to it's self, otherwise set it to the type provided
          object.meta.type = object.meta.type || type;
          //fill the relationships object for this propertyValue
          _this3.createRelationships(parent, [object.id], (0, _emberInflector.singularize)(object.meta.type));

          //send every nested object to the root included object
          _this3.sendToIncluded(object, object.meta.type, includes, parent.id, parent.type);
        } else if (object.meta && !object.meta.included) {
          //object has meta but is not included
          //set the type of the meta to it's self, otherwise set it to the type provided
          object.meta.type = object.meta.type || type;
          //object has meta and is not included
          _this3.createRelationships(parent, [object.id], (0, _emberInflector.singularize)(object.meta.type));
        } else {
          //object has incomplete meta information. Process as normal
          //fill the relationships object for this propertyValue
          _this3.createRelationships(parent, [object.id], (0, _emberInflector.singularize)(type));

          //send every nested object to the root included object
          _this3.sendToIncluded(object, type, includes, parent.id, parent.type);
        }
      });
    },


    /**
     * Handles mapping an object that is nested inside of another response object
     */
    mapNestedObject: function mapNestedObject(parent, object, type, includes) {
      //Example of what the meta object would look like inside of an object 
      /*
        {
          meta: {
            included: false,
            type: 'question'
          },
          id: 7
        }
      */
      if (object.meta && object.meta.included) {
        //object has meta and is said to be included
        //set the type of the meta to it's self, otherwise set it to the type provided
        object.meta.type = object.meta.type || type;
        //fill the relationships object for this propertyValue
        this.createRelationship(parent, object.id, (0, _emberInflector.singularize)(object.meta.type));

        //send every nested object to the root included object
        this.sendToIncluded(object, object.meta.type, includes, parent.id, parent.type);
      } else if (object.meta && !object.meta.included) {
        //object has meta but is not included
        //set the type of the meta to it's self, otherwise set it to the type provided
        object.meta.type = object.meta.type || type;
        //object has meta and is not included
        this.createRelationship(parent, object.id, (0, _emberInflector.singularize)(object.meta.type));
      } else {
        //object has incomplete meta information. Process as normal
        //fill the relationships object for this propertyValue
        this.createRelationship(parent, object.id, (0, _emberInflector.singularize)(type));

        //send every nested object to the root included object
        this.sendToIncluded(object, type, includes, parent.id, parent.type);
      }
    },


    sendToIncluded: function sendToIncluded(record, type, includes, parentId, parentType) {
      //check included to see if an object with this type and id already exist.
      if (!includes.some(function (o) {
        return o.id == record.id && o.type == type;
      })) {
        //Map the included record to a JSONAPI compliant object
        record = this.mapPayload(record, type, includes);
        //create a relationship back to the parent 
        this.createRelationship(record, parentId, parentType);
        //add the object to the includes object
        includes.push(record);
      }
    },

    /**
     * Dynamically generates a relationship. If there is not a hasMany or a belongsTo relationship,
     * it will create a belongsTo. If a belongsTo exists but you try to add another record the relationship
     * will become a hasMany. If a hasMany already exists it will just continue to push new records to it.
     * 
     * Only works with NEW records. Any old records will be ignored.
     * 
     * Example relationship: 
     *  {
     *   "author": {
     *     "data": [
     *       { "type": "people", "id": "9" },
     *       { "type": "people", "id": "10" },
     *     ]
     *   }
     * }
     * 
     * Or 
     * 
     * Example relationship: 
     * {
     *  "author": {
     *     "data": {
     *       "type": "people", "id": "9",
     *     }
     *   }
     * }
     * 
     * @param {*} record 
     * @param {*} relationshipObject 
     * @param {*} type 
     */
    createRelationship: function createRelationship(parent, id, type) {
      (false && !(type) && Ember.assert('Relationship must have a type', type));
      (false && !(id) && Ember.assert('Relationship must have an id', id));
      (false && !(parent) && Ember.assert('Relationship must have a parent', parent));

      //the new relationship to add

      var newRelationship = {
        id: id,
        type: (0, _emberInflector.singularize)(type)
      };

      //check if there is already a relationship record for this relationship
      if (parent.relationships[(0, _emberInflector.pluralize)(type)]) {
        //existing relationships 
        var existing = parent.relationships[(0, _emberInflector.pluralize)(type)].data;

        //if the existing relationships do not contain the new one, then add it.
        if (!this.compareRelationships(newRelationship, existing)) {
          parent.relationships[(0, _emberInflector.pluralize)(type)].data.push(newRelationship);
        }
      } else if (parent.relationships[(0, _emberInflector.singularize)(type)]) {
        //if there is make it plural and add the new record
        //existing relationship
        var _existing = parent.relationships[(0, _emberInflector.singularize)(type)].data;

        //if they are not the same add the new one
        if (!this.compareRelationship(newRelationship, _existing)) {
          //remove the old singular record.
          delete parent.relationships[(0, _emberInflector.singularize)(type)];
          //create a plural record with the existing record and the new record in the array
          parent.relationships[(0, _emberInflector.pluralize)(type)] = {
            data: [_existing, newRelationship]
          };
        }
      } else {
        //if there isn't then add a singular one with the new record
        parent.relationships[(0, _emberInflector.singularize)(type)] = {
          data: newRelationship
        };
      };

      return parent;
    },


    /**
     * Creates a hasMany relationship from the parent, ids, and type provided.
     * Under the hood this forces createRelationship to stickly use it's hasMany functionality.
     * @param {*} parent 
     * @param {*} ids 
     * @param {*} type 
     */
    createRelationships: function createRelationships(parent, ids, type) {
      var _this4 = this;

      if (!parent.relationships[(0, _emberInflector.pluralize)(type)]) {
        parent.relationships[(0, _emberInflector.pluralize)(type)] = {
          data: []
        };
      }
      //walk through each id and create a relationship record for it
      ids.forEach(function (id) {
        return _this4.createRelationship(parent, id, type);
      });
    },


    /**
     * Checks to see if a list of relationships includes the incoming relationship
     * @returns {boolean} True if the relationship is included False if it is not 
     * @param {*} newRelationship 
     * @param {*} oldRelationships 
     */
    compareRelationships: function compareRelationships(newRelationship, oldRelationships) {
      var _this5 = this;

      return oldRelationships.some(function (relationship) {
        return _this5.compareRelationship(newRelationship, relationship);
      });
    },


    /**
     * Checks to see if a relationship is equal to another
     * 
     * @returns {boolean} True if they are equal, false if they are not
     * @param {*} newRelationship 
     * @param {*} oldRelationship 
     */
    compareRelationship: function compareRelationship(newRelationship, oldRelationship) {
      return _lodash.default.isEqual(newRelationship, oldRelationship);
    }
  });
});